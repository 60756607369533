
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import VehicleService from './services/vehicle-service'
import AddDriverModal from './components/AddDriverModal.vue'
import VehicleTypes from '@/data/vehicle-types.json'
import {
  ToastModule
} from '@/store/modules/ToastModule'
  @Component({
    name: 'Driver',
    components: {
      AddDriverModal
    }
  })
export default class Driver extends Vue {
    private isEdit = false
    private searchTable = null
    protected vehicleTypes = VehicleTypes
    protected items: any = []
    protected fields = [{
      key: '#',
      label: '#',
      thStyle: {
        width: '4%'
      },
      class: 'text-right'
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'nic_number',
      label: 'NIC',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'sex',
      label: 'Gender',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'license_no',
      label: 'License No',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'license_expiry_date',
      label: 'License Expiry Date',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'created_user',
      label: 'Created User',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'version',
      class: 'text-center',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '10%'
      },
      class: 'text-center'
    }
    ]

    private genderTypes = ['Male', 'Female']

    mounted () {
      this.items = this.items.map(items => ({
        ...items,
        isEdit: true
      }))
    }

    created () {
      this.boot()
    }

    private boot () {
      this.populateDrivers()
    }

    public openAddModal () {
      (this.$refs.addDriverModal as any).showModal()
    }

    protected async populateDrivers () {
      const response = await VehicleService.getDrivers()
      this.items = response.data.map(item => ({
        ...item,
        isEdit: false,
        type: 'driver',
        category: 'driver',
        modified_user: AuthModule.user.fullname
      }))
    }

    protected isNumber ($event: any) {
      const charCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    }

    protected editRowHandler (data: any) {
      this.items[data.index].isEdit = !this.items[data.index].isEdit
    }

    protected update (item: any) {
      VehicleService.updateDriver(item).then((response: any) => {
        item.isEdit = false
        ToastModule.message(response.data.message)
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected deleteDriver (payload) {
      if (confirm(`Are you sure you want to delete this Driver ${payload.name}?`) === true) {
        VehicleService.deleteDriver(payload).then(response => {
          this.populateDrivers()
          ToastModule.message(response.data.message)
        }).catch(error => {
          console.log(error)
        })
      }
    }

    protected async print () {
      await this.$htmlToPaper('printList')
    }
}

