import APIService from '@/services/api-service'
const resource = '/vehicle'

class VehicleService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  /* public create (data: any) {
    return this.connector.post(`${resource}/create`, data)
  } */

  public save (data: any) {
    return this.connector.post(`${resource}/save`, data)
  }

  public getVehicles () {
    return this.connector.get(`${resource}/list`)
  }

  public getVehicle (id: any) {
    return this.connector.get(`${resource}/modal/${id}/details`)
  }

  public deleteVehicle (payload: any) {
    return this.connector.delete(`${resource}/delete`, { data: payload })
  }

  // Driver routes
  public getDriverNames () {
    return this.connector.get(`${resource}/driver/names`)
  }

  public getDrivers () {
    return this.connector.get(`${resource}/driver/details`)
  }

  public createDriver (data: any) {
    return this.connector.post(`${resource}/driver/create`, data)
  }

  public updateDriver (data: any) {
    return this.connector.put(`${resource}/driver/update`, data)
  }

  public deleteDriver (payload: any) {
    return this.connector.delete(`${resource}/driver/delete`, { data: payload })
  }
}

export default new VehicleService()
