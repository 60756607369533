
import {
  Component,
  Vue
} from 'vue-property-decorator'
import VehicleService from './services/vehicle-service'
import VehicleModal from './components/VehicleModal.vue'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
  @Component({
    name: 'Vehicle',
    components: {
      VehicleModal
    }
  })
export default class Vehicle extends Vue {
    protected isBusy = false
    protected searchTable = null
    protected items: any = []
    protected fields = [{
      key: '#',
      label: '#',
      thStyle: {
        width: '3%'
      },
      class: 'text-right'
    },
    {
      key: 'vehicle_number',
      label: 'Vehicle No',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'type',
      label: 'Type',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'ownership',
      label: 'Ownership',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'wheels',
      label: 'Wheels',
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'capacity',
      label: 'Capacity',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'driver',
      label: 'Driver',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'co_driver',
      label: 'Co-Driver',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'created_user',
      label: 'Created User',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '10%'
      },
      class: 'text-center'
    }
    ]

    mounted () {
      this.items = this.items.map(items => ({
        ...items,
        isEdit: false
      }))
    }

    created () {
      this.boot()
    }

    private boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populateVehicles()
    }

    public openModal (id) {
      (this.$refs.VehicleModal as any).showModal(id)
    }

    protected async populateVehicles () {
      this.isBusy = true
      const response = await VehicleService.getVehicles()
      this.isBusy = false
      this.items = response.data.map(item => ({
        ...item,
        isEdit: false
      }))
    }

    protected isNumber ($event: any) {
      const charCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    }

    protected deleteVehicle (payload) {
      if (confirm(`Are you sure you want to delete this Vehicle ${payload.vehicle_number}?`) === true) {
        VehicleService.deleteVehicle(payload).then(response => {
          this.populateVehicles()
          ToastModule.message(response.data.message)
        }).catch(error => {
          console.log(error)
        })
      }
    }

    protected async print () {
      await this.$htmlToPaper('printList')
    }
}

