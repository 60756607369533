
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import VehicleService from '../services/vehicle-service'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  numeric,
  required,
  minLength
} from 'vuelidate/lib/validators'
import VehicleTypes from '@/data/vehicle-types.json'
import {
  ToastModule
} from '@/store/modules/ToastModule'
  @Component({
    name: 'VehicleModal'
  })
export default class VehicleModal extends Vue {
    protected isEdit = false
    protected drivers: any = []
    protected vehicleTypes = VehicleTypes
    protected vehicle: any = {
      id: null,
      vehicle_number: null,
      type: null,
      ownership: null,
      wheels: null,
      capacity: null,
      driver_id: null,
      driver: null,
      co_driver_id: null,
      co_driver: null,
      created_user: null,
      modified_user: null
    }

    protected ownerships = [{
      value: 'Owned',
      text: 'Owned'
    },
    {
      value: 'Hire',
      text: 'Hire'
    }
    ]

    $v: Vuelidate

    @Validations()
    validations = {
      vehicle: {
        vehicle_number: {
          required,
          minLength: minLength(5)
        },
        type: {
          required
        },
        ownership: {
          required
        },
        wheels: {
          numeric
        }
      }
    }

    public showModal (id = 0) {
      this.$v.$reset()
      this.clear()
      this.populateDrivers()

      if (id !== 0) {
        VehicleService.getVehicle(id).then((response) => {
          this.isEdit = true
          this.vehicle = response.data
          this.vehicle.driver = {
            id: response.data.driver_id,
            name: response.data.driver
          }
          this.vehicle.co_driver = {
            id: response.data.co_driver_id,
            name: response.data.co_driver
          }
        })
      }
      (this.$refs.VehicleModal as any).show()
    }

    public hideModal () {
      this.$v.$reset()
      this.clear();
      (this.$refs.VehicleModal as any).hide()
    }

    protected isNumber ($event: any) {
      const charCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    }

    protected populateDrivers () {
      this.drivers = []
      VehicleService.getDriverNames().then((response) => {
        response.data.forEach((driver) => {
          this.drivers.push({
            text: driver.name,
            value: {
              id: driver.id,
              name: driver.name
            }
          })
        })
      })
    }

    protected save () {
      this.$v.vehicle.$touch()
      if (this.$v.vehicle.$invalid) return false

      const vehicle: any = {}
      vehicle.id = this.vehicle.id
      vehicle.vehicle_number = this.vehicle.vehicle_number
      vehicle.type = this.vehicle.type
      vehicle.ownership = this.vehicle.ownership
      vehicle.wheels = this.vehicle.wheels
      vehicle.capacity = this.vehicle.capacity
      vehicle.driver_id = this.vehicle.driver !== null ? this.vehicle.driver.id : null
      vehicle.driver = this.vehicle.driver !== null ? this.vehicle.driver.name : null
      vehicle.co_driver_id = this.vehicle.co_driver !== null ? this.vehicle.co_driver.id : null
      vehicle.co_driver = this.vehicle.co_driver !== null ? this.vehicle.co_driver.name : null

      if (this.vehicle.id === null) vehicle.created_user = AuthModule.user.fullname
      vehicle.modified_user = AuthModule.user.fullname

      VehicleService.save(vehicle).then((response) => {
        ToastModule.message(response.data.message)
        this.close();
        (this.$parent as any).populateVehicles()
      }).catch(error => {
        console.log(error)
      })
    }

    protected clear () {
      this.reset(this.vehicle)
      this.$v.vehicle.$reset()
    }

    protected close () {
      this.clear()
      this.hideModal()
    }

    protected reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

