
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import VehicleService from '../services/vehicle-service'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  alpha,
  alphaNum,
  numeric,
  required,
  minLength
} from 'vuelidate/lib/validators'
import {
  ToastModule
} from '@/store/modules/ToastModule'
  @Component({
    name: 'JobVehicleModal'
  })
export default class JobVehicleModal extends Vue {
    private isEdit = false
    private genderTypes = ['Male', 'Female']
    private drivers: any = []
    protected driver:any = {
      id: null,
      type: null,
      category: null,
      name: null,
      designation: null,
      nic_number: null,
      sex: null,
      license_no: null,
      mobile_number: null,
      created_user: null,
      modified_user: null
    }

    private driverDesignations = [{
      value: 'Driver',
      text: 'Driver'
    },
    {
      value: 'Co-Driver',
      text: 'Co-Driver'
    }
    ]

    $v: Vuelidate

    @Validations()
    validations = {
      driver: {
        name: {
          required,
          minLength: minLength(5)
        },
        designation: {
          required,
          alpha,
          minLength: minLength(5)
        },
        nic_number: {
          alphaNum
        },
        sex: {
          required
        },
        license_no: {
          alphaNum,
          minLength: minLength(5)
        },
        mobile_number: {
          numeric
        }
      }
    }

    public showModal (id = 0) {
      if (id !== 0) {
        this.isEdit = true
      }
      (this.$refs.NewDriverModal as any).show()
    }

    public hideModal () {
      this.$v.$reset()
      this.clear();
      (this.$refs.NewDriverModal as any).hide()
    }

    private isNumber ($event: any) {
      const charCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    }

    public saveDriver () {
      this.driver.created_user = AuthModule.user.fullname
      this.driver.modified_user = AuthModule.user.fullname
      this.driver.type = 'driver'
      this.driver.category = 'driver'
      this.$v.driver.$touch()
      if (this.$v.driver.$invalid) return false
      VehicleService.createDriver(this.driver).then((response) => {
        ToastModule.message(response.data.message);
        (this.$parent as any).populateDrivers()
        this.reset(this.driver)
        this.$v.driver.$reset()
      }).catch(error => {
        console.log(error)
      })
    }

    private clear () {
      this.reset(this.driver)
    }

    private close () {
      this.$v.$reset()
      this.clear()
      this.hideModal()
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

